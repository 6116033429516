@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;

}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    position: relative;
}

.container .sua-nota{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    bottom: 10%;
    right: 20px;
    z-index: 2;
    background-color: #8FBA4F;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;
}

.container .sua-nota span{
    font-weight: 400;
    font-size: 18px;
    color: #FFF;
}

.container .sua-nota:hover {
    transform: scale(1.1);
}
.container .sua-nota .sua-nota-img{
    width: 200px;
    margin-bottom: 10px;
}

.header {
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #f18140;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

}

.header .logo {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.header .logo img:last-of-type {
    width: 140px;
    margin-left: 10px;
}

.header .menu {
    width: 75%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
}

.header .bars-menu {
    width: 0%;
    visibility: hidden;
}

.header .bars-menu button {
    border: none;
    background-color: transparent;
    visibility: hidden;
}

.header img {
    width: 60px;
}

.header a {
    padding: 10px;
    text-decoration: none;
    color: #FFF;
    font-weight: 300;
    font-size: 18px;
    margin: 0 10px;
    -webkit-text-size-adjust: 20%;
}

.header a:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    background-color: #d37640b4;

}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.body span {
    color: #FF8743;
    font: 500 28px Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    /* border-bottom: 1px solid; */

    text-align: center;
}

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 18px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 15%;
    height: 100%;
    padding-left: 0px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f18140;
    width: 250px;
    height: auto;
    z-index: 1;
    left: -110px;
    padding: 10px;
}

.dropdown-content a {
    color: #FFF;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #d37640b4;
}

.dropdown:hover .dropdown-content {
    display: block;
}


@media only screen and (max-width: 768px) {
    .header {
        
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

    }

    .header .menu {
        display: none;
    }

    .header div {
        width: auto;
        display: flex;

        align-items: center;
        justify-content: flex-start;
        padding-left: 0px;
    }

    .header .bars-menu {
        width: 15%;
        visibility: visible;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;
    }

    .header .bars-menu button {
        border: none;
        background-color: transparent;
        visibility: visible;
    }

    .header img {
        width: 50px;
    }

    .header .logo img:last-of-type {
        width: 110px;
        margin-left: 10px;
    }

    .header a {
        padding: 10px;
        text-decoration: none;
        color: #FFF;
        font-weight: 300;
        font-size: 20px;
        margin: 0px;
        -webkit-text-size-adjust: 20%;
    }

    .modal-header {
        z-index: 2;
        position: absolute;
        top: 278px;
        right: -25%;
        width: 59%;
        height: auto;
        margin-right: 0px;
        transform: translate(-50%, -50%);
        background-color: #FF8743;
        padding: 40px 10px 20px;

    }

    .modal-header .header-links {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .modal-header .header-links a {
        text-decoration: none;
        color: #fff;
        font: 500 18px Roboto, sans-serif;
        border-bottom: .2px ridge #bfbfc1;
        margin-bottom: 10px;
        padding-bottom: 10px;

    }
    
    .container .sua-nota span{
        font-size: 12px;
    }
    .container .sua-nota .sua-nota-img{
        width: 130px;
        margin-bottom: 10px;
    }

}