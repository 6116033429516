

.body .content .form{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.body .content .form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 30px;
}

.body .content .form form input {
    width: 60%;
    height: 40px;
    border-radius: 6px;
    border: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: 5px;
    font: 400 18px Roboto, sans-serif;
    padding-left: 10px;
}

.body .content .form form textarea {
    width: 60%;
    height: 140px;
    border-radius: 6px;
    border: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: 5px;
    font: 400 18px Roboto, sans-serif;
    padding: 10px;
    resize: none;
}

.body .content .form form button {
    width: 60%;
    height: 40px;
    border-radius: 6px;
    border: 0px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: 5px;
    font: 400 16px Roboto, sans-serif;
    background-color: #743ED4;
    color: #FFF;
    cursor: pointer;
}

.body .content .form form button:hover {
    box-shadow: 0px 0px 6px #753ed4b4;
}

.body .content .info {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 5% auto 10%;
    
}

.body .content .info .card-info {
    width: 50%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.body .content .info .card-info .agency {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px auto;
    padding-right: 30%;
}

.body .content .info .card-info p {
    font: 400 18px Roboto, sans-serif;
    text-align: justify;
    margin: 5px;
}
.body .content .info .card-info h2 {
    
    text-align: left;
    margin: 5px;
}

.body .content .info .card-info .maps {
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
    .body .content .form{
        width: 100%;
    }
    
    .body .content .form form input {
        width: 90%;
    }
    
    .body .content .form form textarea {
        width: 90%;
    }
    
    .body .content .form form button {
        width: 90%;
    }
   
    
    .body .content .info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        
    }
    
    .body .content .info .card-info {
        width: 90%;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .body .content .info .card-info .agency {
        
        align-items: center;
        justify-content: center;
        
        padding-right: 0%;
    }
    
    .body .content .info .card-info h2 {
        
        text-align: center;
        
    }
    
    
}