.head-description {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    
}

.head-description .span{
    width: 80%;
    font: 500 20px Roboto, sans-serif;
    margin-top: 20px;
}

.description {
    width: 100%;
    margin: 20px auto;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description p {
    font: 400 20px Roboto, sans-serif;
    margin: 10px auto 20px;
    text-align: center;
}

.flex {
    width: 80%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.flex video{
    height: 80vh;
    object-fit: contain;
}

.flex img {
    width: 100%;
    max-height: 45vh;
}

.grid {
    width: 80%;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0px auto 30px;
    align-items: center;
    justify-items: center;
    grid-gap: 5px 5px;
    
}

.grid div {
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.grid img {
    width: 100%;
    
    cursor: pointer;
}

.grid video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.grid img:hover {
    opacity: .7;
}

.modal{
    margin: 24% 50% 0;
    width: 90%;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.74);
    margin-right: 0px;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.modal img {
    width: auto;
    height: 100%;
}

.modal button {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
}

.modal .left {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: -90%;
    cursor: pointer;
}
.modal .right {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    right: 2%;
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    
    .description {
        width: 100%;
    }

    .description p {
        font: 400 20px Roboto, sans-serif;
        margin: 10px 20px 20px;
        text-align: justify;
        text-indent: 30px;
    }    

    .description h2 {
        align-self: flex-start;
        font: 700 22px Roboto, sans-serif;
        margin: 5px 20px 5px;
        text-align: justify;
    }
    
    .grid {
        width: 90%;
        
        display: flex;
        flex-direction: column;
        margin: 0px auto 30px;
        align-items: center;
        justify-items: center;
        
    }
    
    .modal{
        margin: 100% 50% 0;
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.74);
        margin-right: 0px;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    
    .modal img {
        width: 100%;
        height: auto;
    }
    
    .modal button {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 5%;
        right: 2%;
        cursor: pointer;
    }
    
    .modal .left {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: -90%;
        cursor: pointer;
    }
    .modal .right {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        right: 2%;
        cursor: pointer;
    }
}