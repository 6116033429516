.body .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.project {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
    background-color: #f3f5f5d3;
    padding: 20px 10px 40px;
}

.project div:first-child {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: 20px;
}

.project p {
    text-align: justify;
    margin: 10px 0px 5px;
    font: 400 20px Roboto, sans-serif;
}

.project li {
    text-align: justify;
    margin: 10px 0px 5px;
    font: 400 22px Roboto, sans-serif;
}

.project .imagens-projeto {
    width: 80%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    align-items: center;
    justify-content: center;
    
}

.project .imagens-projeto img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.modal-projects{
    margin: 26% 50% 0;
    width: 90%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.74);
    margin-right: 0px;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.modal-projects img {
    width: auto;
    height: 100%;
}

.modal-projects button {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
}

.modal-projects .left {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: -90%;
    cursor: pointer;
}
.modal-projects .right {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    right: 2%;
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    
    .project {
        width: 98%;
        
    }
    
    .project div:first-child {
        width: 90%;
    }
    
    .project p {
        text-align: justify;
        margin: 10px 0px 5px;
        font: 400 18px Roboto, sans-serif;
    }
    
    .project li {
        text-align: justify;
        margin: 10px 0px 5px;
        font: 400 18px Roboto, sans-serif;
    }
    
    .project .imagens-projeto {
        width: 90%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        align-items: center;
        justify-content: center;
        
    }
    
    
    
    .project .imagens-projeto img {
        width: 100%;
        height: 100%;
        border: 0px;
        border-radius: 0px;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
    }

    .modal-projects{
        margin: 100% 50% 0;
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.74);
        margin-right: 0px;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    
    .modal-projects img {
        width: 100%;
        height: auto;
    }
    
    .modal-projects button {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 5%;
        right: 2%;
        cursor: pointer;
    }
    
    .modal-projects .left {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: -90%;
        cursor: pointer;
    }
    .modal-projects .right {
        background-color: transparent;
        border: none;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        right: 2%;
        cursor: pointer;
    }
}