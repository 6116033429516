.container-register {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    background-color: #c4c4c5;
}

.container-register form {
    width: 30%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    margin-top: 20px;
}

.container-register .photos {
    width: 35%;
    height: auto;
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    padding: 10px;
}
.container-register .photos .card-photos {
    position: relative;
    width: 100%;
    height: 100%;
    
}

.container-register .photos .card-photos div {
    position: absolute;
    width: auto;
    height: auto;
    top: -3px;
    right: -3px;
    cursor: pointer;
}
.container-register .photos img {
    width: 100%;
}

.container-register form div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.container-register form label {
    margin: 5px;
}

.container-register form input {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
}

.container-register form textarea {
    width: 100%;
    height: 120px;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 5px;
    
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.container-register form select {
    width: 100%;
    height: 40px;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 5px;
    
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.container-register form button {
    width: 100%;
    height: 30px;
    background-color: #337ed4;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    margin: 5px;
    cursor: pointer;
}

.container-register form input[type="file" i] {
    
    color: #FFFFFF;
    
}

@media only screen and (max-width: 768px){
    .container-register {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        
    }
    
    .container-register form {
        width: 98%;
        margin-top: 4px;
        padding: 10px;
    }
    
    .container-register .photos {
        width: 98%;
        
        background-color: #FFFFFF;
        margin-top: 5px;
        margin-left: 0px;
    }
    .container-register .photos .card-photos {
        position: relative;
        width: 100%;
        height: 100%;
        
    }
    
    .container-register .photos .card-photos div {
        position: absolute;
        width: auto;
        height: auto;
        top: -3px;
        right: -3px;
        cursor: pointer;
    }
    .container-register .photos img {
        width: 100%;
    }
    
    .container-register form div {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    
    .container-register form label {
        margin: 2px;
    }
    
    .container-register form textarea {
        height: 110px;
    }
}