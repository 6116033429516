.container-admin {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    
    position: relative;
    background-color: #c4c4c4;
    width: 100vw;
    height: 100vh;
    padding-top: 50px;
}

.header-admin {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: #337ed4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 10px;
    color: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.header-admin div {
    cursor: pointer;
    background-color: #30629c;
    width: 5%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mensagens {
    width: 30%;
    height: 100%;
    background-color: #30629c;
    overflow-y: scroll;
}

.mensagens div {
    margin: 10px auto;
    background-color: #FFFFFF;
    width: 90%;
    height: auto;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.mensagens div span {
    font: 500 13px Roboto, sans-serif;
}

.mensagens div p {
    font: 200 13px Roboto, sans-serif;
    margin: 10px auto 10px;
}

.body-admin {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    padding: 10px;
    
}

.body-admin .button-admin {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.body-admin .button-admin button {
    width: 10%;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: rgb(20, 136, 20);
    color: #FFFFFF;
    cursor: pointer;
}

.body-admin .content-admin {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 20px;
}

.body-admin .content-admin .card-admin {
    width: 100%;
    height: 220px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    position: relative;
}
.body-admin .content-admin .card-admin .card-img {
    width: 100%;
    height: 75%;
    overflow: hidden;
}

.body-admin .content-admin .card-admin .card-title {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    
    padding: 3px 0px 0px 10px;
}

.card-buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    width: 20%;
    justify-content: space-between;
    
}

.card-buttons div {
    width: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
}

.body-admin .content-admin .card-admin img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 768px){
    .container-admin {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        
    }
    
    
    
    .header-admin div {
        width: 20%;
    }
    
    .mensagens {
        width: 100%;
        height: auto;
        
        display: flex;
        
    }
    
    .mensagens div {
        margin: 10px;
        padding: 25px;
    }
    
    .mensagens div span {
        font: 500 15px Roboto, sans-serif;
    }
    
    .mensagens div p {
        font: 200 15px Roboto, sans-serif;
        margin: 5px auto;
    }
    
    .body-admin {
        width: 100%;
        height: 100%;
        
        
    }
    
    
    
    .body-admin .button-admin button {
        width: 40%;
        height: 30px;
    }
    
    .body-admin .content-admin {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

}